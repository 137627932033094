<template>
  <MainTemplate current="index.html">
    <h1>{{title}}</h1>
  </MainTemplate>
</template>

<script>
import MainTemplate from '@@/MainTemplate.vue'

export default {
  components: {
    MainTemplate
  },
  data() {
    return {
      title: process.env.VUE_APP_TITLE
    }
  }
}
</script>